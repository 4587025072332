.popup-wrap {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1100; // material dialog > 1300
  transition: 0s;

  > .container.open {
    opacity: 1;
  }
  > .container.close {
    opacity: 0;
  }

  > .popup-container {
    background-color: #fff;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 0 16px;
    position: relative;
    transition: opacity 0.3s;

    // Qrcode popup
    > .popup-header {
      @include font23-600;
      width: 100%;
      padding: 14px 0;
      color: #616161;
      margin-bottom: 12px;
    }

    // 이외 팝업창 제목
    .title-container {
      display: flex;
      padding: 14px 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // height: 56px;

      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      color: #616161;

      svg {
        cursor: pointer;
      }
    }

    // 패스워드 내용
    .password-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      position: relative;

      > p {
        @include font15-400;
        color: #6d7885;
        padding-left: 35px;
        margin-top: 5px;
      }
      > input {
        width: 100%;
        height: 40px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 8px;
        margin-top: 7px;
      }
      > .error {
        position: absolute;
        bottom: -20px;
        color: $error-red;
        padding-left: 0;
        font-size: 12px;
      }
    }

    > .close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .footer {
      margin-top: auto;
      padding: 25px 0 20px 0;
      > button {
        @include font16-500;
        width: 330px;
        height: 44px;
        background-color: $hyper-mint;
        border-radius: 4px;
        border: none;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    }
  }
}

.popup-wrap.open {
  transform: translateX(0);
}
.popup-wrap.close {
  transform: translateX(100%);
}


/* 프로모션 상세보기 팝업창 */
.modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 5%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

/* 상태 팝업창 */
.status-modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 5%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
}
