@import './colors';
.react-datepicker-wrapper {
  width: auto;
  height: fit-content;

  .react-datepicker__input-container::before {
    position: absolute;
    top: 13px;
    left: 10px;
    content: url(../img/svg/calender/calender.svg);
  }
  .react-datepicker__input-container::after {
    position: absolute;
    top: 13px;
    left: 240px;
    content: url(../img/svg/calender/toggle.svg);
  }
  .react-datepicker__input-container > input {
    width: 215px; //260px
    height: 40px;
    border-radius: 8px;
    padding: 0 10px 0 35px;

    color: $high-gray;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
  }
}

.react-datepicker__header {
  border: none !important;
  background-color: white !important;
}
.date-customheader {
  font-family: Noto Sans KR;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .custom-month {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;
  }

  button {
    border: none !important;
    cursor: pointer;
    background: white;
    > svg {
      width: 20px;
      height: 20px;
    }
  }
}

// .react-datepicker__day--in-selecting-range,
// .react-datepicker__day--in-range {
//   color: black;
//   background: #74f5f430;
//   border-radius: 0px;
// }
// .react-datepicker__day--range-start
//   .react-datepicker__day--range-end
//   .react-datepicker__day--in-range
//   .react-datepicker__day--in-selecting-range
//   .react-datepicker__day--selecting-range-start
//   .react-datepicker__day--selecting-range-end {
//   color: black;
//   background: #74f5f430;
//   border-radius: 0px;
// }
// .react-datepicker__day--in-selected {
//   color: black;
//   background: #74f5f430;
//   border-radius: 0px;
// }
// .react-datepicker__day--range-start,
// .react-datepicker__day--range-end {
//   color: black;
//   background: $hyper-mint;
//   border-radius: 100px;
// }

.hyphen {
  width: 30px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.05em;
}
