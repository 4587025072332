@import './colors';
@import './fontStyle';

.root-bg {
  background-color: $background-gray;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: hidden;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal {
  display: flex;
  justify-content: center;
}

.vertical {
  display: flex;
  align-items: center;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.horizontal-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vertical-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-row-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.col-8 {
  display: inline-block;
  width: 80%;
}

.col-2 {
  display: inline-block;
  width: 20%;
}

.input-label {
  @include font18-400;
  display: flex;
  align-items: center;
  color: #000000;
}

// ------------------------------
// 기본 input box
.input-box {
  padding-left: 8px;
  height: 40px;
  border: 1px solid #c2c9d1;
  border-radius: 8px;
  margin: 0;
  font-family: Noto Sans KR;
  box-sizing: border-box; // padding이 영역을 벗어나지 못하게 함
}

.input-box::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

// radio
label > input[type='radio'] {
  display: none;
  font-family: Noto Sans KR;
}
label > input[type='radio'] + * {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Noto Sans KR;
}
label > input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: bottom;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid black;
}
label > input[type='radio']:checked + * {
  color: black;
}
label > input[type='radio']:checked + *::before {
  background: radial-gradient(black 0%, black 40%, transparent 50%, transparent);
  border-color: black;
}

.radio-mint > input[type='radio']:checked + *::before {
  // background: radial-gradient(black 0%, black 40%, transparent 50%, transparent);
  background: radial-gradient($hyper-mint 0%, $hyper-mint 40%, transparent 50%, transparent);
  border-color: $hyper-mint;
}

// select
select.select {
  height: 40px;
  border: 1px solid #c2c9d1;
  border-radius: 8px;
}

// 기본 버튼
.button {
  display: flex;
  width: 200px;
  height: 40px;
  background: #74f5f4;
  border-radius: 8px;
  padding: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  font-family: Noto Sans KR;
  color: $high-gray;
  outline: none;
  border: none;
}

// Link 밑줄 제거용
.text-link {
  color: inherit;
  text-decoration: inherit;
  font-family: Noto Sans KR;
}

// select arrow
select {
  appearance: none;
  height: 40px;
  box-sizing: border-box;
  @include font15-600;
  letter-spacing: 0.2px;
}
.select {
  background: url(../img/svg/calender/toggle.svg) calc(100% - 10px) center no-repeat;
}
select:required:invalid {
  color: $middle-gray;
}
option[value=''][disabled] {
  display: none;
}
option {
  color: black;
}

.textarea {
  // width: 730px;
  // height: 500px;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
  margin: 12px 0 49px 0;
  font-family: Noto Sans KR;
}

// custom BulletPoint
.custom-bullet-point {
  border-style: solid;
  border-radius: 50%;
  margin-right: 12px;
}

.relative {
  display: block;
  position: relative;
}

.button-active {
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    filter: contrast(1.5);
  }
  &:active {
    transform: scale(0.95);
  }
}
.button-disabled {
  cursor: pointer;
  background-color: $middle-gray !important;
  color: $dark-gray !important;
  &:hover {
    transform: scale(1);
  }
  &:focus {
    filter: contrast(1);
  }
  &:active {
    transform: scale(1);
  }
}

.bullet-point {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: $hyper-mint;
}

.error-msg {
  @include font14-600;
  color: $error-red;
  margin: 5px;
}

.data-loading {
  width: 100%;
  height: 100%;

  > svg {
    color: $hyper-mint;
  }
}

.custom-error {
  @include font20-600;
  width: 100%;
  height: 100%;
  color: $error-red;
}

.pointer {
  cursor: pointer;
}

.pagination-footer {
  margin-top: auto;
  padding: 18px 19px;
  > span:nth-child(1) {
    margin-right: 17px;
  }
  > span:nth-child(2) {
    margin-right: 22px;
  }
}

.underline {
  text-decoration: underline;
}

.button {
  @include font16-600;
  display: flex;
  width: 200px;
  height: 40px;
  background: #74f5f4;
  border-radius: 8px;
  padding: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: $high-gray;
}

.pagination-footer {
  margin-top: auto;
  padding: 18px 19px;
  > span:nth-child(1) {
    margin-right: 17px;
  }
  > span:nth-child(2) {
    margin-right: 22px;
  }
}
