@import '../_colors.scss';

.root-bg {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  // min-width: 1252px;
}

.contents-container {
  margin-top: 60px;
  padding: 44px 0 0 45px;
  // padding: 104px 0 0 90px;
  // margin: 164px 90px 104px 90px;
  // margin-right: 90px;
  // min-height: 90vh;
  overflow: auto;
  background-color: $background-gray;
}
