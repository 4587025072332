span.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 48px;
  border-radius: 35px;
  background-color: $hyper-mint;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: $high-gray;
}
span.title-date {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin-left: 18px;
}

.top-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
  margin: 28px 95px 19px 0;
  height: 40px;
  box-sizing: border-box;

  p {
    display: flex;
    margin: 0 5px;
    width: 175px;
    height: 40px;
    background: $hyper-mint;
    border-radius: 35px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    cursor: pointer;

    svg {
      margin-left: 5px;
    }
    span {
      margin: auto;
    }
  }

  .button {
    width: 80px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.05em;
    color: black;
    border-radius: 4px;
    margin-left: 15px;
  }
  // 스토어 매장관리
  .content-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 260px;
    height: 49px;
    left: 32px;
    top: 10px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    background: $middle-gray;
    // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    z-index: 5;
  }
  .content-header.active {
    background: $hyper-mint;
  }
}

// (기본 컨테이너) 메인페이지 레이아웃 비슷한 곳에서 공용으로 쓰임.
.container {
  width: 1500px;
  height: 710px;
  max-height: 710px;
  margin: 0px 90px 50px 0px;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 51px 61px 38px 77px;
}
