// 테이블 - 표
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  // width: clac(1346px + 12px);
  height: 570px;
  max-height: 570px;
  justify-content: space-between;
  overflow-y: auto;

  > .th {
    display: flex;
    background: $low-gray;
    width: 100%;
    max-width: 1346px;
    height: 50px;
    min-height: 50px;
    margin-bottom: 2px;
    align-items: center;
    // justify-content: space-between;

    > p {
      @include font16-600;
      display: flex;
      justify-content: center;
    }
  }

  .rows {
    width: 100%;
    height: 518px;
    max-height: 518px;

    > .row {
      display: flex;
      background: $low-gray;
      width: 100%;
      // max-width: 1346px;
      height: 50px;
      min-height: 50px;
      margin-bottom: 2px;
      align-items: center;
      // justify-content: space-between;

      > .qr-btn {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      > p {
        @include font16-400;
        display: flex;
        justify-content: center;
        svg {
          cursor: pointer;
        }
        span.status {
          width: 18px;
          height: 18px;
          border-radius: 9px;
        }
        span.issue {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 40px;
          background: white;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 4px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          margin: 0 4px;
        }
        span.on {
          background: #3cc13b;
        }
        span.off {
          background: #ff4545;
        }
        span.gray {
          background: rgb(180, 180, 180);
        }
      }
      p.red {
        color: $error-red;
      }
      p.green {
        color: #3cc13b;
      }
    }
  }

  .th {
    @include font18-600;
    background: rgba(116, 245, 244, 0.3);

    max-width: calc(1500px); // - 14px
  }

  .description {
    display: flex;
    width: 100%;
    min-height: 102px;
    background: rgba(116, 245, 244, 0.1);

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.05em;
    margin-bottom: 2px;

    > div {
      margin: 14px 0 14px 0;
    }

    .menu {
      display: flex;
      width: 40%;
      border-right: 1px solid $middle-gray;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 60%;
      margin-left: 30px;
      margin-right: 30px;
      div {
        display: flex;
      }
    }

    p.title {
      font-weight: bold;
      margin-right: 15px;
      min-width: 70px;
    }
    div.content {
      // display: flex;
      // flex-direction: column;
      width: 80%;
      -moz-column-count: 3;
      -moz-columns: 3;
      -webkit-columns: 3;
      columns: 3;
    }
  }

  .row:disabled {
    & > * {
      disabled: true;
      cursor: default;
      pointer-events: none;
    }
  }
}
