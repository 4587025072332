@import '../_colors.scss';

.modal {
  width: 370px;
  height: 458px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 10;

  .title-container {
    display: flex;
    padding: 14px 16px;
    // align-items: center;
    justify-content: space-between;
    // width: 100%;
    // height: 56px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #616161;
    border-radius: 8px 8px 0 0;

    svg {
      cursor: pointer;
    }
  }
  .content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 14px 20px 23px 20px;
    .column {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        width: 48%;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 18px;
      p {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
      }
      // .select {
      //   min-height: 40px;
      //   height: -webkit-fill-available;
      //   font-family: Noto Sans KR;
      //   font-style: normal;
      //   font-weight: 500;
      //   font-size: 15px;
      //   letter-spacing: 0.2px;
      //   // width: 35%;
      // }
      input::placeholder {
        color: $middle-gray !important;
      }
      .input-box {
        border: 1px solid black;
        min-height: 40px;
        // height: -webkit-fill-available;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.2px;
        padding: 0 14px 0 20px;
      }
      > div {
        display: flex;
        margin: 0 !important;
      }
    }

    .texts {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.2px;
    }

    .loading-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 330px;
      height: 150px;
      background: rgba(0, 0, 0, 0.05);
      border: 1px dashed rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      svg {
        margin-top: 41px;
        margin-bottom: 26px;
      }

      span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.05em;
      }
    }
    .loading-status.uploading {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3e66fb;
      border-radius: 4px;
    }
    .loading-status.fail {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f03738;
      border-radius: 4px;
    }
    .loading-status.success {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3cc13b;
      border-radius: 4px;
    }
    .loading-status.menu {
      width: 187px;
      height: 150px;
      margin: 0 auto 13px;
    }

    .text-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 18px 0 10px;
      background: #f4f4f4;
      border-radius: 8px;

      svg {
        margin-right: 12px;
      }
      span.blue {
        color: #0075ff;
      }
      span.red {
        color: #ff4545;
      }
    }
  }
  .button {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #000000;
  }
}

.menu-content {
  width: 550px;
  height: 120px;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
  padding: 7px 18px 0 10px;
  margin: 0;
  font-family: Noto Sans KR;
}

// 이미지 업로드용
.modal.image {
  height: 340px;

  .content-container {
    padding-top: 0;
  }
  .button.image {
    margin-top: 24px;
  }
}
