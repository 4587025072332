@import './colors';

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  // width: 100%;
  width: calc(100vw - 240px);
  height: 60px !important;
  top: 0px;
  background: #ffffff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
  z-index: 99;
}

.header-title {
  min-width: 146px;
  width: auto;
  height: 31px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.05em;
  color: #1c1f37;
}

.header-right {
  display: flex;
  max-height: 60px;
  margin-right: 74px;

  svg {
    margin-left: 16px;
  }

  .user-name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}
