@import './colors';
.side-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  background-color: #ffffff;
  color: #000000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
  overflow-y: auto;

  .horizontal {
    padding-top: 68px;
    padding-bottom: 95px;
  }
}

.menu-list {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // flex-wrap: wrap;
  // gap: 60p;
  // height: -webkit-fill-available;

  .menu {
    display: flex;
    align-items: center;
    width: calc(100%);
    height: 40px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.05em;
    color: $high-gray;
    margin-bottom: 40px;

    svg {
      margin-left: 49px;
      margin-right: 26px;
    }
  }

  .active {
    background-color: rgba(116, 245, 244, 0.2);
    border-right: 5px solid $hyper-mint;
  }
}
.terms {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 60px;
  p {
    height: 18px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.05em;
    color: $high-gray;
    margin: 10px 0;
    cursor: pointer;
  }
}
