.coupon-create-modal {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 540px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 10;

  .title-container {
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    height: 56px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #616161;

    svg {
      cursor: pointer;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    // padding: 0 17px 32px 17px;
    padding: 35px 26px 22px 24px;

    .column {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        width: 48%;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 18px;
      p {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
      }
      // .select {
      //   min-height: 40px;
      //   height: -webkit-fill-available;
      //   font-family: Noto Sans KR;
      //   font-style: normal;
      //   font-weight: 500;
      //   font-size: 15px;
      //   letter-spacing: 0.2px;
      //   // width: 35%;
      // }
      input::placeholder {
        color: $middle-gray !important;
      }
      .input-box {
        border: 1px solid black;
        min-height: 40px;
        // height: -webkit-fill-available;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.2px;
      }
      > div {
        display: flex;
        margin: 0 !important;
      }
    }
    .money,
    .count {
      position: relative;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.2px;

      // display: inline-block;
      // width: 100%;
    }
    .money::after {
      position: absolute;
      top: 40px;
      left: 240px;

      // content: url(../img/svg/calender/toggle.svg);
      content: '원';
    }
    .count::after {
      position: absolute;
      top: 40px;
      left: 240px;
      content: '매';
    }
  }
}
