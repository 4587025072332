.store-main-container {
  display: flex;
  flex-direction: column;
  width: 730px;
  height: 450px;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .title-container {
    display: flex;
    align-items: center;
    width: 730px;
    height: 60px;
    // padding: 0 30px;
    background: rgba(196, 196, 196, 0.2);
    border-radius: 10px 10px 0px 0px;

    span {
      padding: 9px 0;
      margin: 0 30px;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.05em;
      color: $high-gray;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    .content {
      width: 100%;
      max-width: -webkit-fill-available;
      display: flex;
      margin: 115px 61px 0 41px;
      .left,
      .right {
        display: flex;
        flex-direction: column;
        width: 40%;
        p.total {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 600;
          font-size: 23px;
          line-height: 31px;
          letter-spacing: -0.05em;
        }
        p.error {
          margin-top: 11px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 26px;
          letter-spacing: -0.05em;
          color: #616161;
        }
      }
      .right {
        width: 60%;
        p {
          text-align: end;
        }
        p.total {
          font-size: 35px;
        }
        p.error {
          font-size: 15px;
        }
      }
    }
    .link {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      svg {
        margin: 130px 61px 56px auto;
        cursor: pointer;
      }
    }
  }
}

// 스토어 매장관리
.store-main-container.branch {
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-around;
  width: 1500px;
  height: 710px;
  position: relative;

  .qrcode-div {
    width: auto;
    height: auto;
    margin: 24px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .button-div {
    display: flex;
    width: auto;
    height: auto;
    margin: 24px;
    position: absolute;
    right: 35px;
    top: -80px;
    cursor: pointer;

    .button {
      width: 80px;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.05em;
      color: black;
      border-radius: 4px;
      margin-left: 10px;
    }
  }

  > .content-div {
    width: 475px;
    // height: 100%;
    margin: 55px 0;

    > div {
      display: flex;
      flex-direction: column;
      margin: 48px 0 0 62px;
    }

    p.title {
      display: flex;
      align-items: center;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.05em;
      margin-bottom: 44px;
      svg {
        margin-right: 19px;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      margin-bottom: 27px;
      p {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.2px;
        margin-bottom: 5px;
      }
      .item-input {
        display: flex;
      }
      .input-box {
        display: flex;
        align-items: center;
        width: 260px;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        // color: $middle-gray;
        letter-spacing: 0.2px;
        border-color: black;
        margin: 0;
      }
      .input-box::placeholder {
        color: $middle-gray;
      }
      .input-box.mint {
        border-color: $hyper-mint !important;
      }
      .select {
        width: 260px;
      }
      .label {
        display: flex;
        align-items: center;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.05em;
        margin-left: 20px;
      }
      .button {
        width: 80px;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.05em;
        background: $middle-gray;
        color: black;
        margin-left: 10px;
      }
    }
  }
}

// 메뉴 페이지 table
.menu-container {
  width: 1402px;
  height: 710px;
  margin-top: 22px;

  // 테이블 - 표
  .menu-table {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 1800px;
    height: 718px;
    max-height: 718px;
    // justify-content: space-between;
    // padding-top: 51px;

    .menu-rows {
      width: 100%;
      max-width: 1402px;
      height: 658px;
      max-height: 658px;
      overflow-y: scroll;
    }

    .menu-row {
      display: flex;
      background: none;
      // width: 100%;
      // max-width: 1346px;
      height: 162px;
      margin-bottom: 2px;
      align-items: center;
      border-bottom: 1px solid $high-gray;

      p {
        display: flex;
        justify-content: center;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.05em;

        img {
          border-radius: 16px;
          // cursor: pointer;
        }
      }
      // 메뉴 관련 Input
      input[type='text'].text-box {
        width: 100%;
        min-height: 50px;
        background: white;
        border: 1px solid black;
        border-radius: 8px;
        align-items: center;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        line-height: 25px;
        letter-spacing: -0.05em;
      }
      // input[type='text'].text-box:disabled {
      //   background: $low-gray;
      // }

      // 메뉴 번호(인덱스)
      .index {
        display: flex;
        justify-content: center;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.05em;

        padding-top: 27px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        span {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: -0.05em;
        }
      }
    }

    .th {
      height: 50px;
      background: rgba(116, 245, 244, 0.3);
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.05em;
      max-width: 1402px;
      border-bottom: none;
    }
  }
}

.origin-button {
  @include font20-600;
  display: flex;
  width: 80px;
  color: black;
  border-radius: 4px;
  margin: 12px 16px 0 auto;
  background: #74f5f4;
  height: 40px;
  padding: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.editMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 132px;
  justify-content: space-around;
  z-index: 1;
  top: 100px;
  right: 0;
  background: white;
  border: 0.5px solid #000000;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  p {
    display: flex;
    align-items: center;
    height: 42px;
    cursor: pointer;
    svg {
      width: 35%;
    }
    span {
      width: 65%;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.24px;
      text-align: center;
      justify-content: center;
    }
  }
  p:hover {
    background: #f4f4f4;
  }
}

.bottom-container {
  @include font16-500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // height: 32px;
  // margin-top: 25px;
}
