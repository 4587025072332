@import '../_colors.scss';

.test {
  color: $hc-primary;
}

// header - 두 줄
.header {
  position: absolute;
  height: 49px !important;
  .top {
    height: 26px;
    background-color: $hyper-mint;
  }
  .bottom {
    height: 23px;
  }
}

// 화면 내용부
.contents {
  min-height: calc(100vh - 49px);
  // padding-top: 60px;
  // padding-bottom: 150px;

  .title {
    width: 368px;
    height: 44px;
    margin: 22px 0 130px 55px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.05em;
    color: $high-gray;
  }

  // 로그인 영역
  .login-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 450px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    .logo-container {
      display: flex;
      margin: 44px auto 50px auto;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 0 23px 0 25px;
      margin-bottom: 47px;
    }
  }
  .copyright {
    margin-top: 42px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
}
