// 기본 버튼
.login-button {
  @include font15-600;
  display: flex;
  width: 200px;
  height: 40px;
  background: #74f5f4;
  border-radius: 8px;
  padding: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
