@import './colors';
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');
@import url('https://fonts.cdnfonts.com/css/poetsenone');

html,
body {
  height: 100%;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
}

#root {
  height: 100%;
  background-color: #f5f7fb;
}

.root-wrap {
  height: 100%;
  overflow: auto;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select {
  margin: 0;
  padding: 0;
}

body {
  padding: 0px !important;
  margin: 0px !important;
}

div {
  box-sizing: border-box;
}

p {
  margin: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

input[type='checkbox'].checkbox {
  width: 18px;
  height: 18px;
}

::-webkit-scrollbar {
  width: 10px;
  background: #f4f4f4;
  border-radius: 10px;
  margin-left: 10px;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  // display: none;
  // background-color: black;
}

::-webkit-scrollbar {
  width: 10px;
  scroll-margin-left: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}
