// // Colors System

$hc-primary: #50009b !default;
$hc-secondary: #ff5500 !default;
$hc-third: #ffca00 !default;
$hc-bg-color: #f5f7fb !default;

// ColorChips
$red: #ff0000;
$blue: #0075ff;
$error-red: #ff6162;
$origin-black: #000000;
$middle-gray: #bcbcbc;
$low-gray: #f4f4f4;
$allow-blue: #3c4c5e;
$high-gray: #545454;
$green: #3cc13b;
$hyper-mint: #74f5f4;
$background-gray: #f5f7fb;
$mint-opactiy: rgba(116, 245, 244, 0.3);
$spread-more-info: rgba(116, 245, 244, 0.1);
$dark-gray: #616161;
$yellow: #ffcd4b;

/* Background */
.red {
  background-color: $error-red !important;
}

.blue {
  background-color: $blue !important;
}

.green {
  background-color: $green !important;
}

.hyper-mint {
  background-color: $hyper-mint !important;
}

.yellow {
  background-color: $yellow !important;
}

.middle-gray {
  background-color: $middle-gray !important;
}

/* Font */
.high-gray-font {
  color: $high-gray !important;
}

.white-font {
  color: #fff !important;
}

.green-font {
  color: $green !important;
}

.red-font {
  color: $red !important;
}

.blue-font {
  color: $blue !important;
}

.yellow-font {
  color: $yellow !important;
}
.gray-font {
  color: $middle-gray !important;
}
