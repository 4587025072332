@import '../_colors.scss';

.textarea-modal {
  width: 770px;
  height: 709px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 10;

  .title-container {
    display: flex;
    padding: 14px 16px;
    // align-items: center;
    justify-content: space-between;
    // width: 100%;
    // height: 56px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #616161;

    svg {
      cursor: pointer;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 23px 20px;

    .texts {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.2px;
    }

    > .modal-title {
      padding: 10px;
      height: 40px;
      overflow: hidden;
      width: 730px;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 4px;
      resize: none;
      margin: 12px 0 10px 0;
      font-family: Noto Sans KR;
    }

    > .textarea {
      @include font16-400;
      width: 730px;
      height: 450px;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 4px;
      resize: none;
      margin: 12px 0 49px 0;
      padding: 16px;
    }
  }
}
