@import './../colors';
.coupon-detail-modal {
  display: flex;
  flex-direction: column;
  width: 1100px;
  height: 739px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 10;

  .title-container {
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    height: 56px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #616161;

    svg {
      cursor: pointer;
    }
  }
  .middle-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 47px;
    margin-right: 41px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.05em;

    > p {
      display: flex;
      align-items: center;
    }

    span.red {
      color: #fe4b4c;
    }
    span.green {
      color: #3cc13b;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 0 17px 32px 17px;

    .modal-table {
      display: flex;
      flex-direction: column;
      // width: 1054px;
      // width: clac(1054px + 12px);
      height: 570px;
      max-height: 570px;
      overflow-y: scroll;

      .row {
        display: flex;
        background: $low-gray;
        width: 100%;
        height: 50px;
        min-height: 50px;
        margin-bottom: 2px;
        align-items: center;
        // justify-content: space-between;

        p {
          display: flex;
          justify-content: center;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.05em;
          span.status {
            width: 18px;
            height: 18px;
            border-radius: 9px;
          }
          span.on {
            background: #3cc13b;
          }
          span.off {
            background: #ff4545;
          }
          span.end {
            background: $middle-gray;
          }

          svg {
            cursor: pointer;
          }
          span.issue {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 40px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .th {
        background: #bcbcbc;
        font-family: Noto Sans KR;
        color: white;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.05em;
      }

      .rows {
        height: 518px;
        max-height: 518px;
        // overflow-y: scroll;
      }
    }
  }
}
