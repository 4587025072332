.dropdown-container {
  @include font18-400;

  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  width: 200px;
  height: auto;
  // right: 15px;
  right: 0px;
  top: 58px;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.12);

  > div {
    width: 100%;
    height: 44px;
    background-color: #fff;
    > svg {
      margin: 7px 22px 7px 18px;
    }
    &:hover {
      filter: brightness(0.9);
    }
  }
  // 패스워드 수정
  > div:nth-child(1) {
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  // CS 접수 안내
  > div:nth-child(2) {
    background-color: #f4f4f4;
  }
  // 이용약관
  > div:nth-child(3) {
    background-color: #fff;
  }
  // 사용정책
  > div:nth-child(4) {
    background-color: #f4f4f4;
  }
  // 공지사항
  > div:nth-child(5) {
    background-color: #fff;
    .notice-badge {
      @include font15-bold;
      margin-left: 13px;
      width: 26px;
      height: 26px;
      border-radius: 100px;
      background-color: $blue;
      color: #fff;
    }
  }
  // 로그 아웃
  > div:nth-child(6) {
    background-color: #f4f4f4;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.dropdown-container:after {
  border-top: 0 solid transparent;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #ffffff;
  content: '';
  position: absolute;
  top: -18px;
  right: 9px;
}
