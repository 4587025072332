@import '../_colors.scss';

.notice-modal {
  width: 370px;
  // height: 270px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 10;

  .title-container {
    display: flex;
    padding: 14px 16px;
    // align-items: center;
    justify-content: space-between;
    // width: 100%;
    // height: 56px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: #616161;

    svg {
      cursor: pointer;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 14px 20px 20px 20px;

    .texts {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #6d7885;
      margin-bottom: 4px;
    }

    .input-box {
      border: 1px solid black;
      margin: 3px 0;
    }
    .input-box::placeholder {
      color: #6d7885;
    }
    .input-box.red {
      border: none;
      background: rgba(254, 75, 76, 0.1);
    }
    .input-box.red::placeholder {
      color: $error-red;
    }
    .input-box.blue {
      border: none;
      background: rgba(0, 117, 255, 0.1);
    }
    .input-box.blue::placeholder {
      color: $allow-blue;
    }

    .success {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0 2px 0;

      div {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: 0.2px;
        text-align: center;
        margin-top: 24px;
      }
    }
  }
}
