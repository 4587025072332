@import './colors';

// 말 줄임표 생략
.ellipsis {
  display: block !important;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 굵게
.bolder {
  font-weight: bolder !important;
}

@mixin font14-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem; // 19px
  letter-spacing: -0.05em;

  color: $high-gray;
}

@mixin font15-400 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1.5rem; // 24px
  letter-spacing: -1px;
  color: #000000;
}
@mixin font15-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 1.5rem; // 24px
  letter-spacing: -1px;
  color: #000000;
}
@mixin font15-bold {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 0.938rem;
  line-height: 1.5rem; // 24px
  letter-spacing: -1px;
  color: #000000;
}

@mixin font16-400 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem; // 22px
  letter-spacing: -0.05em;
  color: #000000;
}

@mixin font16-500 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem; // 22px
  letter-spacing: -0.05em;
  color: #000000;
}

@mixin font16-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem; // 22px
  letter-spacing: -0.05em;
  color: #000000;
}

@mixin font18-400 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.25rem; // 20px
  letter-spacing: -0.24px;
  color: #000000;
}
@mixin font18-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25rem; // 20px
  letter-spacing: -0.24px;
  color: #000000;
}

@mixin font20-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.688rem; // 27px
  letter-spacing: -0.05em;
  color: #000000;
}

@mixin font22-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.875rem; // 30px
  letter-spacing: -0.05em;
  color: #000000;
}

@mixin font23-600 {
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.438rem;
  line-height: 1.75rem; // 28px
}
